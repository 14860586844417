// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family:'AleoSans';
    line-height: 1.6;
    margin: 20px;
}

p {
    margin: 10px 0;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
table, th, td {
    border: 1px solid #ddd;
}
th, td {
    padding: 10px;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,gBAAgB;AACpB","sourcesContent":["body {\n    font-family:'AleoSans';\n    line-height: 1.6;\n    margin: 20px;\n}\n\np {\n    margin: 10px 0;\n}\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 20px 0;\n}\ntable, th, td {\n    border: 1px solid #ddd;\n}\nth, td {\n    padding: 10px;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
