// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/AleofontSans-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Input (disabled) - Make box appear not disabled
 *
 * https://www.w3schools.com/cssref/pr_class_cursor.asp
 */
input[type="text"]:disabled {
    cursor: text;
}

@font-face {
    font-family: "AleoSans";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
    font-weight: normal;
    font-style: normal;
}

.headerDark {
    font-family: "AleoSans";
    color: white;
    font-size:32px;
    margin-left:2rem;
    a {
        color: white;
    }
}

.headerLight {
    font-family: "AleoSans";
    color: black;
    font-size:32px;
    margin-left:2rem;
    a{
        color: black;
    }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;EAIE;AACF;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,+DAAiE;IACjE,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB;QACI,YAAY;IAChB;AACJ","sourcesContent":["/*\n * Input (disabled) - Make box appear not disabled\n *\n * https://www.w3schools.com/cssref/pr_class_cursor.asp\n */\ninput[type=\"text\"]:disabled {\n    cursor: text;\n}\n\n@font-face {\n    font-family: \"AleoSans\";\n    src: url(\"../public/AleofontSans-Regular.otf\") format(\"opentype\");\n    font-weight: normal;\n    font-style: normal;\n}\n\n.headerDark {\n    font-family: \"AleoSans\";\n    color: white;\n    font-size:32px;\n    margin-left:2rem;\n    a {\n        color: white;\n    }\n}\n\n.headerLight {\n    font-family: \"AleoSans\";\n    color: black;\n    font-size:32px;\n    margin-left:2rem;\n    a{\n        color: black;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
